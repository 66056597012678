import { type AppConfigType } from '@/types'

export const appConfig: AppConfigType = {
  api: {
    url: import.meta.env.VITE_APP_EHR_API_URL || '/api',
  },
  auth0: {
    domain: import.meta.env.VITE_APP_AUTH0_DOMAIN || 'anzolo.us.auth0.com',
    clientId: import.meta.env.VITE_APP_AUTH0_CLIENT_ID || '',
    audience: import.meta.env.VITE_APP_AUTH0_AUDIENCE || '',
  },
}
